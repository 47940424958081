const toggleActiveButton = () => {
  const buttonList = document.querySelector('[data-button-list]');
  if (!buttonList) {
    return;
  }
  const buttons = document.querySelectorAll('[data-button]');

  const removeActive = () => {
    buttons.forEach((item) => {
      item.classList.remove('active');
    });
  };

  buttons.forEach((item) => {
    item.addEventListener('click', function () {
      removeActive();
      item.classList.add('active');
    });
  });
};

export {toggleActiveButton};
