const filterButton = document.querySelector('.search-form__filter-button');
const block = document.querySelector('.search-form__select-block');

const toggleCatalogFilter = () => {
  if (!filterButton) {
    return;
  }

  const onFilterButtonClick = () => {
    block.classList.toggle('is-active');
    filterButton.classList.toggle('is-active');
  };

  filterButton.addEventListener('click', onFilterButtonClick);
};


export {toggleCatalogFilter};
