const menuButtons = document.querySelectorAll('.js-sidebar-toggle');
const sidebarNav = document.querySelector('.sidebar-nav');

const toggleCatalogMenu = () => {
  if (!menuButtons) {
    return;
  }

  

  const onMenuButtonClick = (evt) => {
    evt.preventDefault();
    sidebarNav.classList.toggle('is-active');
    
  };

  menuButtons.forEach(btn => btn.addEventListener('click', onMenuButtonClick));

};

export {toggleCatalogMenu};
