const togglePromo = () => {
  const button = document.querySelector('.block-price__coupon-button');
  if (!button) {
    return;
  }
  const promo = document.querySelector('.block-price__coupon-wrapper');
  button.addEventListener('click', (evt) => {
    evt.preventDefault();
    promo.classList.toggle('is-active');
  });
};


export {togglePromo};
