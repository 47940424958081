import {disablePageScroll, enablePageScroll} from '../vendor/scroll-lock';

const disableBodyScrollIos = () => {
  disablePageScroll();
};

const enableBodyScrollIos = () => {
  enablePageScroll();
};

export {disableBodyScrollIos, enableBodyScrollIos};
