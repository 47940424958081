import {
  openModal,
  closeModal
} from './modals/modal';

const sliderStories = () => {
  const clientsSlides = document.querySelectorAll('.modal-stories__container');
  const DELAY_IN_MS = 10000;
  if (!clientsSlides) {
    return;
  }

  clientsSlides.forEach((slider) => {
    // eslint-disable-next-line no-undef
    const swiper = new Swiper(slider, {
      slidesPerView: 1,
      slidesPerGroup: 1,
      grabCursor: true,
      observer: true,
      observeParents: true,
      loop: false,
      navigation: {
        nextEl: slider.closest('.modal').querySelector('.modal-stories__button-next'),
        prevEl: slider.closest('.modal').querySelector('.modal-stories__button-prev'),
      },
      pagination: {
        el: '.swiper-pagination',
      },
    });

    slider.swiper = swiper;

    swiper.on('slideChange', function () {
      const modalActive = document.querySelector('.modal-stories.modal--active');
      if (!modalActive) {
        return;
      }
    });

    const openNextModal = () => {
      let modalStories = document.querySelectorAll('.modal--stories');
      let activeModal = document.querySelector('.modal--active');
      if (!activeModal) {
        return;
      }
      let slides = activeModal.querySelectorAll('.modal-stories__slide');
      let quantity = slides.length;
      let idActive = Number(activeModal.id);

      closeModal(activeModal);
      let nextIndex;
      if (idActive < quantity) {
        nextIndex = idActive + 1;
      } else {
        nextIndex = 0;
      }
      let nextModal = modalStories[nextIndex];

      openModal(nextModal);
      startSlider(nextModal);
    };

    slider.progress = 0;
    slider.procent = 0;
    let isPaused = false;

    const getInterval = () => setInterval(() => {
      if (slider.progress >= DELAY_IN_MS) {
        clearInterval(slider.interval);
        return;
      }

      slider.progress += 100;
      slider.procent = slider.progress / DELAY_IN_MS;

      if (slider.progress >= DELAY_IN_MS) {
        if (!slider.swiper.isEnd) {
          slider.progress = 0;
          slider.procent = 0;

          if (slider.interval) {
            slider.swiper.slideNext();
          }
        } else {
          openNextModal();
        }
      }

      slider.style.setProperty('--progress', slider.procent);
    }, 100);

    slider.getInterval = getInterval;

    slider.addEventListener('click', () => {
      isPaused = !isPaused;

      if (isPaused) {
        clearInterval(slider.interval);
        slider.interval = null;
      } else {
        if (!slider.interval) {
          slider.interval = getInterval();
        }
      }
    });

    swiper.on('slideChange', () => {
      clearInterval(slider.interval);
      slider.interval = null;

      isPaused = false;

      slider.progress = 0;
      slider.procent = 0;
      slider.style.setProperty('--progress', '0');

      if (!slider.interval) {
        slider.interval = getInterval();
      }
    });
  });
};

const startSlider = (modal) => {
  const slider = modal.querySelector('.modal-stories__container');

  slider.progress = 0;
  slider.procent = 0;
  slider.style.setProperty('--progress', '0');

  slider.swiper.slideTo(0, 0);

  if (!slider.interval) {
    slider.interval = slider.getInterval();
  }
};

const stopSlider = (modal) => {
  const slider = modal.querySelector('.modal-stories__container');

  clearInterval(slider.interval);
  slider.interval = null;
};

export {sliderStories, startSlider, stopSlider};
