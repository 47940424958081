export class FixBlock {

  constructor(block) {

    this.block = block;
    this.header = document.querySelector('.header__wrapper');
    this.footer = document.querySelector('.footer');
    this.caalogHeader = document.querySelector('.catalog__header');
    this.caalogWrapper = document.querySelector('.catalog__wrapper');
    this.virtualBlock = document.querySelector('.js-virtual-block');

  }


  _makeSticky() {
    this.virtualBlock.style.height = this.block.getBoundingClientRect().height + 'px';
    this.block.classList.add('is-sticky');
    this.block.style.top = this.header.getBoundingClientRect().height + 'px';
  }

  _makeStatic() {
    this.block.classList.remove('is-sticky');
  }

  _defineCurrentState() {

    const stickyBlockStaticPos = this.virtualBlock.offsetTop -    
    this.header.getBoundingClientRect().height;

    const windowOffset = window.pageYOffset;

    const footerFlag = this.footer.getBoundingClientRect().y - this.footer.getBoundingClientRect().height
     - this.header.getBoundingClientRect().height;

    return (windowOffset >= stickyBlockStaticPos && footerFlag > 0);

  }

  _onDocReady() {
    this.performState();
  }

  _initScroll() {
    window.addEventListener('scroll', (e) => {
      this.performState();
    });
  }

  _resizeFlag() {
    return window.matchMedia('screen and (min-width: 1024px').matches;
  }

  performState() {
    
    if (!this._resizeFlag()) return;
   
    if (this._defineCurrentState()) {

      this._makeSticky();

    } else {

      this._makeStatic();
    }
  }

  init() {
    this._onDocReady();
    this._initScroll();
    
  }

}