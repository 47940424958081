
const toggleMenu = () => {
  const paragraphs = document.querySelectorAll('.specialist-item__text');
  const elements = document.querySelectorAll('.specialist-item__about');
  const bottomBlock = document.querySelector('.specialists__left');
  const buttons = document.querySelectorAll('.buttons-list__item');
  const buttonIcons = document.querySelectorAll('.specialists-item__button');
  const specialistButtons = document.querySelectorAll('.buttons-list__button');

  // Чтобы не было ошибок на других страницах
  if (!bottomBlock) {
    return;
  }

  const toggleClass = function (element, selector) {
    element.classList.toggle(selector);
  };

  const removeClass = function (element, selector) {
    element.classList.remove(selector);
  };

  if (buttons) {
    buttons.forEach(function (button, i) {
      button.addEventListener('click', function () {
        elements.forEach(function (element) {
          removeClass(element, 'specialist-item__about--active');
        });
        paragraphs.forEach(function (element) {
          removeClass(element, 'specialist-item__text--active');
        });
        buttonIcons.forEach(function (element) {
          removeClass(element, 'specialists-item__button--active');
        });
        specialistButtons.forEach(function (element) {
          removeClass(element, 'buttons-list__button--active');
        });
        toggleClass(specialistButtons[i], 'buttons-list__button--active');
      });
    });
  }
};

export {toggleMenu};
