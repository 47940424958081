import {
  TagFiltration
} from './TagFiltrationMain';

let analyzesTags;

const initAnalysisTags = () => {

  let container = document.querySelector('.js-tag-container');

  if (!container) {
    return
  }

  analyzesTags = new TagFiltration('.js-tag-container');
  analyzesTags.init();
};

let complexesTags;

const initComplexesTags = () => {

  let container = document.querySelector('.js-tag-container-complexes');

  if (!container) {
    return
  }

  complexesTags = new TagFiltration('.js-tag-container-complexes');
  complexesTags.init();
};


export {
  initAnalysisTags,
  initComplexesTags,
  analyzesTags,
  complexesTags
}
