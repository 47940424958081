export class DynamicPagination {

  constructor(container) {

    this.container = container;
    this.ajaxBlock = document.querySelector('#ajax-rubrics-data');
    this.ajaxUrl = this.ajaxBlock.dataset.ajaxUrl;

  }

  _getActiveType() {
    return this.container.querySelector('.tabs__content').querySelector('.is-active');
  }

  _getCurrentPagination() {

    return this._getActiveType().querySelector('.js-pagination-block');
  }

  _removeLoadBlock() {
    let loadBlock = document.querySelector('.js-catalog-intersection-target');
    loadBlock ? loadBlock.remove() : false;
  }

  _nextPaginationStep() {

    if (!this._getCurrentPagination()) return false;

    let total = this._getCurrentPagination().querySelector('.js-total-page');

    if (!total) return false;

    let totalPages = parseInt(total.dataset.paginationTotalpage);

    let current = this._getCurrentPagination().querySelector('.js-current-page');
    let currentStep =  parseInt(current.dataset.paginationCurrpage);

    if (currentStep == totalPages) {
      this._removeLoadBlock();
      return false;
    } else {
      return currentStep + 1;
    }


  }

  async _request() {

    let page = this._nextPaginationStep();
    let params = getURLParameters();
    if (!page) return false;

    let currentRubric = () => {

      const curUrl = new URL(window.location);
      if (curUrl.pathname.indexOf('rubric/') > 0) {
        const rubricPos = curUrl.pathname.lastIndexOf('rubric/');
        const rubricNumberEnd = curUrl.pathname.indexOf('/', rubricPos + 7);
        const rubricNumber = curUrl.pathname.slice(rubricPos + 7, rubricNumberEnd)
        return rubricNumber;
      } else {
        return '';
      }
    }

    params['rubric'] = currentRubric();
    params['city'] = this.ajaxBlock.dataset.city;
    params['city-name'] = this.ajaxBlock.dataset.cityName;
    params['page'] = page;



    return await $.ajax({
      url: this.ajaxUrl,
      type: 'GET',
      data: params,
      cache: false,
      async: true,
      dataType: 'json',
    });

  }

  _dataInjection() {

    let requestResponse = this._request();

    requestResponse.then(data => {

      if (!data) {
        return false;
      } else {
        const parsed = new DOMParser().parseFromString(data.html, 'text/html');
        return parsed;
      }
    }).then(parsed => {

      const currentPag = this._getCurrentPagination();
      const currentList = this._getActiveType().querySelector('.js-paginator-list');

      if (parsed) {

        const parsedPag = parsed.querySelector('.js-pagination-block');
        const parsedList = parsed.querySelector('.js-paginator-list');

        currentPag.innerHTML = parsedPag ? parsedPag.innerHTML : '';
        currentList.insertAdjacentHTML('beforeend', parsedList.innerHTML);
        if (parsedList.innerHTML.trim() == '') this._removeLoadBlock();

      }
    })

  }

  _scrollListener() {

    const injection = () => {
      this._dataInjection();
    }

    const options = {
      // root: document.querySelector( '#viewport' ), // я закомментил строку, чтобы использовать значение по умолчанию
      rootMargin: '300px',
      threshold: [0]
    };

    const trueCallback = function (entries, observer) {

      entries.forEach((entry) => {

        if (entry.isIntersecting) {
          injection();
        }

      });
    }

    const target = this.container.querySelector('.js-catalog-intersection-target');

    if (!target) return;

    const observer = new IntersectionObserver(trueCallback, options);

    observer.observe(target)
  }

  init() {
    this._scrollListener();
  }


}
