import {Tabs} from './tabs-2';

let tabs;

const initTabs2 = () => {
  tabs = new Tabs();
  // Используйте в разработке экспортируемую переменную tabs, window сделан для бэкэнда
  window.tabs = tabs;
};

export {initTabs2, tabs};
