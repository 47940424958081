import {SERVISES} from './../data/servises';
const createSelectOption = (id, serviceId, text, price, isActive = false) => {
  const option = document.createElement('li');

  option.classList.add('custom-select__item');
  option.tabIndex = '0';
  option.dataset.selectValue = id;
  option.ariaSelected = isActive;
  option.role = 'option';

  const itemId = document.createElement('span');
  itemId.classList.add('custom-select__item-id');
  itemId.textContent = serviceId;
  option.insertAdjacentElement('beforeend', itemId);

  const itemText = document.createElement('span');
  itemText.classList.add('custom-select__item-text');
  itemText.textContent = text;
  option.insertAdjacentElement('beforeend', itemText);

  const itemPrice = document.createElement('span');
  itemPrice.classList.add('custom-select__item-price');
  itemPrice.textContent = price;
  option.insertAdjacentElement('beforeend', itemPrice);

  return option;
};

const reRenderSelectOptions = (select, data) => {
  const selectOptions = select.querySelector('.custom-select__list');

  selectOptions.innerHTML = '';

  data.forEach((service) => {
    selectOptions.insertAdjacentElement('beforeend', createSelectOption(service.id, service.value, service.text, service.cost, service.isActive));
  });

  select.classList.remove('not-empty', 'is-initialized', 'is-valid');
  select.querySelector('select').remove();

  window.selectInit();

};

const initSpecialistServices = () => {

  const cards = document.querySelector('.specialists__cards');

  if (!cards) {
    return;
  }

  const select = document.querySelector('.price-select');

  const onCardClick = (evt) => {
    if (evt.target !== evt.target.closest('.specialists__card input')) {
      const doc = evt.target.closest('.specialists__card');

      if (doc) {
        const input = doc.querySelector('input');

        if (!input.checked) {
          reRenderSelectOptions(select, SERVISES[doc.dataset.id]);
        }
      }
    }
  };

  cards.addEventListener('click', onCardClick);
};

export {initSpecialistServices, reRenderSelectOptions};
