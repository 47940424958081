const initinputCode = () => {
  const inputs = document.querySelectorAll('[data-input-code]');
  if (!inputs.length) {
    return;
  }

  for (let i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener('input', function () {
      if (inputs[i].value) {
        let nextIndex = i + 1;
        if (nextIndex < inputs.length) {
          inputs[nextIndex].focus();
        }
      }
    });

    inputs[i].addEventListener('click', function () {
      if (!inputs[i].value) {
        for (let k = 0; k < inputs.length; k++) {
          if (!inputs[k].value) {
            inputs[k].focus();
            return;
          }
        }
      }
    });

    inputs[i].addEventListener('keydown', function (event) {
      const key = event.key;
      if (key === 'Backspace' || key === 'Delete') {
        if (i === 0 && !inputs[i].value) {
          return;
        }
        if (inputs[i].value) {
          inputs[i].value = '';
          return;
        } else {
          let prevIndex = i - 1;
          inputs[prevIndex].value = '';
          inputs[prevIndex].focus();
          return;
        }
      }
    });
  }
};

export {initinputCode};
