import { AnalyzesTags } from "./AnalyzesTags";

const initAnalyzesPageTags = function () { 

  let analyzesPage = document.querySelector('.js-analyzes-page-tags');

  if (!analyzesPage) {
    return
  }

  const analyzesTags = new AnalyzesTags('.js-analyzes-page-tags');

  analyzesTags.initSelects();

  window.analyzesTags = analyzesTags;
}

export {initAnalyzesPageTags};