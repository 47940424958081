import { tabs } from "./init-tabs-2";

export class TagFiltration {

  constructor(containerClass) {
    this.container = document.querySelector(containerClass);

  }

  _define() {

    if (!this.container) {
      return
    }

    this.tagItems = this.container.querySelectorAll('.js-tag-btn-container');
    this.ajaxUrl = this.container.dataset.ajaxUrl;
    this.updateContainer = this.container.querySelector('.js-tag-list');
    this.complex = this.container.dataset.complex ? true : false;
  }

   /* общая инициализация  */

  init() {

    this._define();


    this.tagItems.forEach(tag => {

      tag.addEventListener('click', (evt) => {
        evt.preventDefault();

          this.makeActive(tag);
          // this.constructQuery();
          this.request();

      });
    });
  }

   /* метод переключения тегов  */

  makeActive(tag) {
    this.tagItems.forEach(tag => tag.classList.remove('buttons-group__item--active'))

    tag.classList.add('buttons-group__item--active');


  }

  /* метод по созданию строки запроса  */

  constructQuery() {
    let query;

    // Если фильтруются комплексы - добавляем ?complex=1 в начало запроса

    this.complex ? query = "?complex=1" : query = "?";

    this.activeTags = [...this.tagItems].filter(tag => {
      return tag.classList.contains('buttons-group__item--active');
    })


    this.activeTags.forEach(tag => {

      if (tag.querySelector('.js-tag-btn').dataset.id) {
        query += "&" + tag.querySelector('.js-tag-btn').dataset.tagType + "=" + tag.querySelector('.js-tag-btn').dataset.id;
      } else {
        // если id = none (кнопка все) - очищаем запрос
        // query = null;
      }

    })

    return query
  }

   /* метод ajax отправки данных (по аналогии со списком анализов)  */

  request() {
    // урл формируется из датаатрибута data-ajax-url + query
    let queryFinal = this.constructQuery();
    let url = this.ajaxUrl + queryFinal;


    window.history.pushState(null, null, queryFinal);

    let item = $('#ajax-rubrics-data'),
        success = item.data('ajax-success'),
        update = this.updateContainer;

    let params = getURLParameters();
    params['city'] = item.data('city');
    params['city-name'] = item.data('city-name');


    $.ajax({
        url: url,
        type: 'GET',
        cache: false,
        data: params,
        dataType: 'json',
        async: false,
        success: function (data) {
            /** @param data.description **/
            if (update) {
                // $(update).html('');
                $(update).html(data.html);
                // setTimeout(function () {
                //   window.tabs.reInit();
                  // window.tabs._updateTabHeight();
                // }, 50);

                bootstrapAjax($);
                // tabs.reInit();
                // tabs._updateTabHeight();

                try {
                    mindbox("async", {
                        operation: "Website.ViewCategory",
                        data: {
                            viewProductCategory: {
                                productCategory: {
                                    ids: {
                                        labstoriRu: elem.data('rubric')
                                    }
                                }
                            }
                        },
                    });

                } catch (e) {
                    console.log(e)
                }

            }
            let fn = new Function('', success);
            if (data.error) {
                console.log('ERROR: ' + data.error);
            } else if (typeof (fn) === "function") {
                return fn();
            }
        },
        error: function (xhr, errmsg, err) {
            $('#results').html("<div>oops! error: " + errmsg + "</div>");
            console.log(xhr.status + ": " + xhr.responseText);
        },
        complete: function () {

          // tabs.openTab(document.querySelector('.analyzes__toggle:not(.is-active)'));
          // tabs.reInit();
          // tabs._updateTabHeight();
          window.initTabs();
          // document.querySelector('analyzes__toggle is-active')

        }
    });
    return false;


  }

}
