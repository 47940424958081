const addCard = (element = false) => {

  // возвращает куки с указанным name,
  // или undefined, если ничего не найдено
  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  const container = document.querySelector('.card__wrap');

  function mindboxSetBasketList(getProductsList = false) {
    var productList = [];

    var headerHiddenBasket = document.querySelector('.js-hidden-basket-products'),
      hiddenBasketProducts = headerHiddenBasket.querySelectorAll('.js-hidden-basket-product');

    for (let i = 0; i < hiddenBasketProducts.length; i++) {
      productList.push({
        product: {
          ids: {
            labstoriRu: String(hiddenBasketProducts[i].querySelector('.js-product-id').textContent.trim()),
          },
        },
        count: '1',
        pricePerItem: String(hiddenBasketProducts[i].querySelector('.js-product-price').textContent.trim()),
      });
    }

    if (getProductsList) { // Если нужно просто получить массив, не отправляя его в mindbox
      return JSON.stringify(productList);
    }

    localStorage.setItem('mindBoxProductList', JSON.stringify(productList));

    try {
      mindbox('async', {
        operation: 'Website.SetCartList',
        data: {
          productList: productList,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  if (!container) {
    return;
  }

  let buttons = document.querySelectorAll('.button-add');
  if (element) {
    buttons = element.querySelectorAll('.card__add');
  }
  let cards = document.querySelectorAll('.card-item');

  let toggleAddCard = function () {
    buttons.forEach((item, index) => {
      item.addEventListener('click', function () {
        let productId = item.dataset.id;
        let execution = item.dataset.execution;
        // let timeOn = item.dataset.time_on;
        // let dateOn = item.dataset.date_on;
        let url = document.querySelector('.action-add-to-cart').value;
        let urlDel = document.querySelector('.action-del-from-cart').value;
        if (item.closest('.card__item').classList.contains('card-add')) {
          url = urlDel;
        }

        let xhr = new XMLHttpRequest();

        xhr.open('POST', url, true);
        xhr.setRequestHeader('csrfmiddlewaretoken', getCookie('csrftoken'));
        xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        let formData = new FormData();
        formData.append('product_id', productId);
        formData.append('execution', execution);

        try {
          xhr.send(formData);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }

        xhr.onload = function () {
          item.classList.toggle('card-add');
          document.querySelector('.js-header-basket').innerHTML = xhr.response;
          mindboxSetBasketList();
        };

      });
    });
  };

  toggleAddCard();
};

export {addCard};
