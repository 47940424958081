import {analyzesTags, complexesTags} from "./initTagFiltration";


export const initTabsSwithcing = function () {

const block = document.querySelector('.js-main-category-switch');

if (!block) {
  return
}

let analyzesSwitch = block.querySelector('.analyzes__toggle--analyzes');
let complexesSwitch = block.querySelector('.analyzes__toggle--complexes');

analyzesSwitch.addEventListener('click', () => {

  window.history.pushState(null, null, window.location.origin + window.location.pathname);
  analyzesTags.request();
});

complexesSwitch.addEventListener('click', () => {

  window.history.pushState(null, null, window.location.origin + window.location.pathname + '?complex=1');
  complexesTags.request();
});

}
