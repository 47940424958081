const buttons = document.querySelectorAll('.order-card__reset-button');

const initAnimation = () => {
  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      button.closest('div').classList.add('is-animated');
    });
  });

};

export {initAnimation};
