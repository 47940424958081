const addPaddingHeader = () => {
  const headerNews = document.querySelector('.header__news');
  const headerBlock = document.querySelector('.header__block');
  if (!headerNews && !headerBlock) {
    return;
  }

  let div = document.createElement('div');

  div.style.overflowY = 'scroll';
  div.style.width = '50px';
  div.style.height = '50px';

  document.body.append(div);
  let scrollWidth = div.offsetWidth - div.clientWidth;

  div.remove();

  headerNews.style.paddingRight = scrollWidth + 'px';
  headerBlock.style.paddingRight = scrollWidth + 'px';

};

const removePaddingHeader = () => {
  const headerNews = document.querySelector('.header__news');
  const headerBlock = document.querySelector('.header__block');
  if (!headerNews && !headerBlock) {
    return;
  }

  headerNews.style.paddingRight = '';
  headerBlock.style.paddingRight = '';
};

export {addPaddingHeader, removePaddingHeader};
