const formFocus = () => {
  const input = document.querySelector('.search__input');
  const form = document.querySelector('.search__form');

  if (!form) {
    return;
  }

  input.onfocus = function () {
    form.classList.add('search__form--active');
  };

  input.onblur = function () {
    form.classList.remove('search__form--active');
  };
};

const openSearchModalCallback = (modal) => {
  const inputModal = modal.querySelector('.search__input');

  setTimeout(() => {
    inputModal.focus();
  }, 400);
};

export {formFocus, openSearchModalCallback};
