import {
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';

const toggleHeaderMenu = () => {
  const header = document.querySelector('.header');
  const body = document.querySelector('body');
  const menu = document.querySelector('.menu');
  const toggleMenu = document.querySelector('.header__button-menu');

  if (!menu) {
    return;
  }

  const overlay = header.querySelector('.header__overlay');

  window.addEventListener('load', () => {
    menu.style.display = 'block';
    overlay.style.display = 'block';
  });

  body.classList.add('there-is-js');

  const opendMenu = () => {
    toggleMenu.setAttribute('aria-label', 'Закрыть меню');
    header.classList.add('is-menu-open');
    disableBodyScroll(menu, {
      reserveScrollBarGap: true,
    });
    overlay.classList.add('header__overlay--show');
  };

  const closedMenu = () => {
    toggleMenu.setAttribute('aria-label', 'Открыть меню');
    header.classList.remove('is-menu-open');
    enableBodyScroll(menu);
    overlay.classList.remove('header__overlay--show');
  };

  toggleMenu.addEventListener('click', function () {
    if (header.classList.contains('is-menu-open')) {
      closedMenu();
    } else {
      opendMenu();
    }
  });

  const onEscPress = (evt) => {
    const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';
    if (isEscKey) {
      evt.preventDefault();
      closedMenu();
    }
  };

  document.addEventListener('keydown', onEscPress);
  overlay.addEventListener('click', closedMenu);
};

export {
  toggleHeaderMenu
};
