

export const modalSearchSubmit = () => {
  const modalForm = document.querySelector('.js-modal-search');

  if (!modalForm) {
    return
  }

  modalForm.addEventListener('submit', (evt) => {

    evt.preventDefault();

    const analyzesPageUrl = evt.target.getAttribute('action');
    const searchQuery = evt.target.querySelector('input[name="search"]').value;

    window.location.replace(analyzesPageUrl + "?search=" + searchQuery); 

  });
  
}