import {
  iosVhFix
} from './utils/ios-vh-fix';

import {
  initModals
} from './modules/modals/init-modals';
import {
  toggleHeaderMenu
} from './modules/toggle-header-menu';
import './modules/custom-select';
import './modules/form-validate';
import './modules/init-form-validate';
import {
  toggleMenu
} from './modules/toggle-specialist';
import {
  openModal
} from './modules/open-modal';
import {
  calendar
} from './modules/calendar';

import './modules/tabs';
import {
  sliderStories
} from './modules/slider-stories';
import {
  toggleFilter
} from './modules/toggle-filter';
import {
  noTouch
} from './modules/no-touch';
import {
  modalSearch
} from './modules/modal-search';
import {
  initSpecialistServices
} from './modules/init-specialist-services';

import {
  initSpecialistFilter
} from './modules/specialists-services';
import {
  formFocus
} from './modules/form-focus';
import {
  addCard
} from './modules/add-card';
import {
  toggleTab
} from './modules/toggle-tab';
import {
  nawsHeader
} from './modules/news-header';
import {
  cookiePopup
} from './modules/cookie-popup';
import {
  showRubric
} from './modules/show-rubric';

import {
  toggleSideMenu
} from './modules/toggle-side-menu';
import {
  initAccordions
} from './modules/init-accordion';
import {
  initTabs2
} from './modules/init-tabs-2';
import {
  scrollAnchors
} from './modules/scroll-anchors';
import {
  showAllCards
} from './modules/show-all-cards';
import {
  sliderComplex
} from './modules/slider-complex';
import {
  modalAddres
} from './modules/modal-address';
import {
  toggleCatalogFilter
} from './modules/toggle-catalog-filter';
import {
  toggleCatalogMenu
} from './modules/toggle-catalog-menu';
import {
  togglePromo
} from './modules/toggle-promo';
import {
  initActiveCheckbox,
  toggleButton
} from './modules/init-active-checkbox';
import {
  fixBlock
} from './modules/fix-block';
import {
  fixBlockNew
} from './modules/fix-block-new-init';
import {
  toggleActiveButton
} from './modules/toggle-active-button';
import {
  inputMask,
  intlInputPhone
} from './modules/input-mask';
import {
  initAnimation
} from './modules/init-animation';
import {
  initinputCode
} from './modules/init-input-code';
import {initAnalysisTags, initComplexesTags} from './modules/initTagFiltration';
import {initAnalyzesPageTags} from './modules/initAnalyzesPageTags';
import {initTabsSwithcing} from './modules/main-category-tabs';
import {modalSearchSubmit} from './modules/modal-search-submit';
import {findButtonEnabling} from './modules/findButtonEnabling';
import {initDynamicPagination} from './modules/initDynamicPagination';

// ---------------------------------

document.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();

  // Modules
  // ---------------------------------
  initTabsSwithcing();
  toggleHeaderMenu();
  calendar();
  sliderStories();
  // toggleFilter();
  toggleMenu();
  openModal();
  noTouch();
  modalSearch();
  // initSpecialistServices();
  initSpecialistFilter();
  formFocus();
  modalSearchSubmit();
  // addCard();
  toggleTab();
  nawsHeader();
  cookiePopup();
  showRubric();
  toggleSideMenu();
  initAccordions();
  initTabs2();
  scrollAnchors();
  showAllCards();
  sliderComplex();
  modalAddres();
  toggleCatalogFilter();
  toggleCatalogMenu();
  togglePromo();
  initActiveCheckbox();
  // toggleButton();
  // fixBlock();
  fixBlockNew();
  toggleActiveButton();
  inputMask();
  // initAnimation();
  initinputCode();
  intlInputPhone();
  initAnalysisTags();
  initComplexesTags();
  initAnalyzesPageTags();
  findButtonEnabling();
  initDynamicPagination();
  initModals();

});


