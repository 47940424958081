const modalSearch = () => {
  const modal = document.querySelector('.modal--search-result');

  if (!modal) {
    return;
  }
  const buttons = modal.querySelectorAll('.modal-search__button');
  const blocks = modal.querySelectorAll('.modal-search__result-js');

  buttons[0].classList.add('modal-search__button--active');
  blocks[0].classList.add('modal-search__result-js--active');

  const removeActive = function () {
    buttons.forEach((item) => {
      item.classList.remove('modal-search__button--active');
    });
  };

  buttons.forEach(function (item, i) {
    item.addEventListener('click', function () {
      removeActive();

      blocks.forEach((block) => {
        block.classList.remove('modal-search__result-js--active');
      });


      buttons[i].classList.add('modal-search__button--active');
      blocks[i].classList.add('modal-search__result-js--active');
    });
  });
};

export {modalSearch};
