const block = (function() {
  return document.querySelector('[data-sticky]');
}());
const tabsContainer = (function() {
  return document.querySelector('.tabs');
}());

const header = document.querySelector('.header__container');
const sidebar = document.querySelector('.sidebar-nav');
const catalogHeader = document.querySelector('.catalog__header');
const wrapper = document.querySelector('.catalog__wrapper');
const breakpoint = window.matchMedia('(min-width:1024px)');

const virtualBlock = document.createElement('div');

const startScroll = () => {
  virtualBlock.style.height = block.getBoundingClientRect().height + 'px';
  block.classList.add('is-sticky');
  block.style.top = header.offsetHeight + 'px';
  block.parentNode.insertBefore(virtualBlock, block);

  const scrollTop = block.getBoundingClientRect().top - sidebar.getBoundingClientRect().height;
  const scrollBottom = block.getBoundingClientRect().top + block.getBoundingClientRect().height - tabsContainer.getBoundingClientRect().bottom + header.offsetHeight;

  if (scrollTop <= scrollBottom) {
    block.classList.remove('is-sticky');
    block.classList.add('is-absolute');
    block.style.top = tabsContainer.offsetHeight - sidebar.offsetHeight + block.offsetHeight + catalogHeader.offsetHeight - parseInt(getComputedStyle(wrapper, true).marginTop, 10) + 'px';

  } else {
    block.classList.remove('is-absolute');
    block.classList.add('is-sticky');
    block.style.top = 84 + 'px';
  }
};

const stopScroll = () => {
  block.classList.remove('is-sticky');
  block.classList.remove('is-absolute');

  if (block.parentNode.contains(virtualBlock)) {
    block.parentNode.removeChild(virtualBlock);
  }
};

const fixBlock = () => {
  if (!block) {
    return;
  }

  const sticky = block.offsetTop - header.offsetHeight;

  const onScrollFix = () => {
    if (!block) {
      return;
    }

    if (window.pageYOffset >= sticky) {
      startScroll();
    } else {
      stopScroll();
    }
  };

  const breakpointChecker = () => {
    if (breakpoint.matches) {
      window.addEventListener('scroll', onScrollFix);
    } else {
      window.removeEventListener('scroll', onScrollFix);
      stopScroll();
    }
  };

  breakpoint.addListener(breakpointChecker);
  breakpointChecker();
};

window.fixBlock = fixBlock;

export {fixBlock};
