const sliderComplex = () => {
  const slider = document.querySelector('.slider-complex__container');
  if (!slider) {
    return;
  }
  const buttonPre = document.querySelector('.slider-complex__button--pre');
  const buttonNext = document.querySelector('.slider-complex__button--next');

  // eslint-disable-next-line
  const swiper = new Swiper(slider, {
    slidesPerView: 1,
    slidesPerGroup: 1,
    grabCursor: true,
    spaceBetween: 12,
    observer: true,
    observeParents: true,
    // loop: true,
    navigation: {
      nextEl: buttonPre,
      prevEl: buttonNext,
    },
    breakpoints: {
      768: {
        spaceBetween: 60,
      },
      1024: {
        spaceBetween: 10,
      },
    },
  });
};

export {sliderComplex};
