import { FixBlock } from "./fix-block-new";

export const fixBlockNew = function () {

  const container = document.querySelector('[data-sticky]');

  if (!container) return;

  const catalogFix = new FixBlock(container);

  catalogFix.init();
  
}