const noTouch = () => {
  // eslint-disable-next-line no-undef
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    document.body.classList.remove('no-touch');
  } else {
    document.body.classList.add('no-touch');
  }
};

export {noTouch};
