const toggleFilter = () => {
  const toggles = document.querySelectorAll('.analyzes__btn');
  let buttonsGroup = document.querySelectorAll('.analyzes__buttons');
  let analyzes = document.querySelector('.analyzes');

  if (!analyzes) {
    return;
  }

  buttonsGroup[0].querySelector('.buttons-group__item').classList.add('buttons-group__item--active');
  buttonsGroup[1].querySelector('.buttons-group__item').classList.add('buttons-group__item--active');

  toggles.forEach((element, i) => {
    buttonsGroup = document.querySelectorAll('.analyzes__buttons');

    let buttons = buttonsGroup[i].querySelectorAll('.buttons-group__item');

    buttonsGroup[i].onclick = function (event) {
      buttons.forEach((item) => {
        item.classList.remove('buttons-group__item--active');
      });

      if (event.target.parentNode.classList.contains('buttons-group__item')) {
        event.target.parentNode.classList.add('buttons-group__item--active');
      }
    };

    element.addEventListener('click', function () {
      buttons = buttonsGroup[i].querySelectorAll('.buttons-group__item');

      buttonsGroup[i].onclick = function (event) {
        buttons.forEach((item) => {
          item.classList.remove('buttons-group__item--active');
        });

        if (event.target.parentNode.classList.contains('buttons-group__item')) {
          event.target.parentNode.classList.add('buttons-group__item--active');
        }
      };
    });
  });
};

export {toggleFilter};
