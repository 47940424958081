const cookiePopup = () => {
  const cookiePopupElement = document.querySelector('.cookie-popup');

  if (cookiePopupElement) {
    const btn = cookiePopupElement.querySelector('.cookie-popup__btn');

    btn.addEventListener('click', () => {
      cookiePopupElement.classList.add('cookie-popup--hidden');
    });
  }
};

export {cookiePopup};
