import {findButtonEnabling} from './findButtonEnabling';
import { dynamicPagination } from "./initDynamicPagination";

export class AnalyzesTags {

  constructor(catalogClass) {
    this.catalogFilters = document.querySelector(catalogClass);
  }

  _define() {

    if (!this.catalogFilters) {
      return
    }

    this.selectsContainer = document.querySelector('.js-tag-select-container');

    this.options = this.selectsContainer.querySelectorAll('.js-tag-option');

    this.outputContainers = document.querySelectorAll('.js-applyed-tag-container');

    this.selects = this.selectsContainer.querySelectorAll('.custom-select');

    this.ajaxBlock = document.querySelector('#ajax-rubrics-data');
    this.ajaxUrl = this.ajaxBlock.dataset.ajaxUrl;
    this.ajaxResponseContainer = this.ajaxBlock.dataset.ajaxUpdate;



    this.resetBtn = `
    <button class="output__reset js-applyed-tag-reset" type="reset" aria-label="Очистить">Сбросить все</button>
    `;

  }

  initSelects() {

    this._define();

    this._initDeleting();
    this._clearAllInit();
    this._updateCount();

    this.options.forEach(opt => {
      opt.addEventListener('click', () => {

        this.tagsPerforming();
        this._updateCount();

      })
    })
  }

  showLoader(blockClass) {
    const loader = `
        <div class="catalog__loader">
            <svg class="catalog__loader-icon" width="40" height="40" aria-hidden="true">
            <use xlink:href="#icon-load"></use>
            </svg>
            <p class="catalog__text">Ищем анализы и комплексы...</p>
        </div>
        `
    let loaderContainer = document.querySelector(blockClass);

    if (!loaderContainer) return;

    loaderContainer.innerHTML = loader;

  }

  _defineCurrentTab() {
    this.currentTabsContainer = document.querySelector('.tabs-controls');

    this.activeTab = [...this.currentTabsContainer.querySelectorAll('.js-catalog-tabs-btn')].find(btn => btn.classList.contains('is-active'));

    return this.activeTab.dataset.catalogTabs;
  }

  addingLabels() {

    this.outputContainers.forEach(container => {
      container.innerHTML = "";
    });

    this._returnActiveOptions().forEach(opt => {
      this._applyedCreation(opt.dataset.tagType, opt.dataset.selectValue, opt.dataset.tagTitle);
    })

    if (this._returnActiveOptions().length > 0) {
      this.outputContainers.forEach(container => {
        container.insertAdjacentHTML('beforeend', this.resetBtn)
      });
    }

  }

  _returnActiveOptions() {
    return this.activeOptions = [...this.options].filter(opt => {
      return opt.getAttribute('aria-selected') == 'true';
    });
  }

  tagsPerforming() {

    let query = location.search;
    if (query.length > 0) {
      if (this._defineCurrentTab() == 'checkup') {
        query += "&complex=1";
      }
    } else {
      if (this._defineCurrentTab() == 'analysis') {
        query = "?";
      } else if (this._defineCurrentTab() == 'checkup') {
        query = "?complex=1";
      }
    }

    query = new URLSearchParams(location.search.substring(1));

    let paramsNames = [];
    let options = document.querySelectorAll('.js-tag-option');
    options.forEach(function (option) {
      let name = option.dataset.tagType;
      if (!(name in paramsNames)) {
        paramsNames.push(name);
      }
    });

    if (paramsNames.length > 0) {
      paramsNames.forEach(function (name) {
        query.delete(name);
      });
    }

    query = query.toString();

    let activeOptions = this._returnActiveOptions();


    activeOptions.forEach(opt => {
      if (query.length > 1) {
        if (query.indexOf(opt.dataset.tagType + '=' + opt.dataset.selectValue) === -1) {
          query += '&' + opt.dataset.tagType + '=' + opt.dataset.selectValue;
        }
      } else {
        query += '?' + opt.dataset.tagType + '=' + opt.dataset.selectValue;
      }
    });

    if (!query.startsWith('?')) {
      query = '?' + query;
    }

    this.query = query;

    if (query.length > 0) {
      window.history.pushState(null, null, query);
    } else {
      window.history.pushState(null, null, '?');
    }
    this.showLoader('.js-catalog-loader-container');

    this.addingLabels();

    this._request();

  }

  _updateCount() {
    let overall = 0;

    this.selects.forEach(select => {
      let counter = select.querySelector('.custom-select__counter');
      let mobileCounter = document.querySelector('.js-mobile-counter');
      overall += [...select.querySelectorAll('li[aria-selected="true"]')].length;
      counter.textContent = [...select.querySelectorAll('li[aria-selected="true"]')].length;

      mobileCounter.textContent = overall;
    });

  }

  _request() {

    let update = this.ajaxResponseContainer,
      url = this.ajaxUrl + this.query;

    let params = getURLParameters();

    let currentRubric = () => {

      const curUrl = new URL(window.location);
      if (curUrl.pathname.indexOf('rubric/') > 0) {
        const rubricPos = curUrl.pathname.lastIndexOf('rubric/');
        const rubricNumberEnd =  curUrl.pathname.indexOf('/', rubricPos + 7);
        const rubricNumber = curUrl.pathname.slice(rubricPos + 7, rubricNumberEnd)
        return rubricNumber;
      } else {
        return '';
      }
    }

    params['rubric'] = currentRubric();
    params['city'] = this.ajaxBlock.dataset.city;
    params['city-name'] = this.ajaxBlock.dataset.cityName;


    $.ajax({
      url: url,
      type: 'GET',
      cache: false,
      data: params,
      dataType: 'json',
      success: function (data) {
        /** @param data.description **/
        if (update) {
          $(update).replaceWith(data.html);
          $('.js-wrapper-description').html(data.description);
          bootstrapAjax($);
          window.tabs._init();
          dynamicPagination.init();

          try {
            mindbox("async", {
              operation: "Website.ViewCategory",
              data: {
                viewProductCategory: {
                  productCategory: {
                    ids: {
                      labstoriRu: elem.data('rubric')
                    }
                  }
                }
              },
            });

          } catch (e) {
            console.log(e)
          }

        }
      },
      error: function (xhr, errmsg, err) {
        $('#results').html("<div>oops! error: " + errmsg + "</div>");
        console.log(xhr.status + ": " + xhr.responseText);
      },
      complete: function () {
        findButtonEnabling();
      }
    });
    return false;


  }

  _applyedCreation(type, id, title) {

    let classType;

    if (type == 'tags') {
      classType = 'output__option--tag';
    } else {
      classType = '';
    }

    let btnBody = `

    <button class="output__option ${classType} js-applyed-tag" type="reset" data-id="${id}" data-type="${type}" aria-label="Очистить">${title}
        <svg width="8" height="8" aria-hidden="true">
            <use xlink:href="#icon-close"></use>
        </svg>
    </button>

    `;

    this.outputContainers.forEach(container => {
      container.insertAdjacentHTML('beforeend', btnBody);
    });

  }

  _initDeleting() {
    document.addEventListener('click', (evt) => {

      if (evt.target.classList.contains('js-applyed-tag') || evt.target.closest('.js-applyed-tag')) {
        let target = evt.target.classList.contains('js-applyed-tag') ? evt.target : evt.target.closest('.js-applyed-tag');

        let type = target.dataset.type;
        let id = target.dataset.id;

        let targetSelect = [...this.selects].find(select => select.dataset.type == type);

        let targetOption = targetSelect.querySelector(`li[data-select-value="${id}"]`);

        targetOption.setAttribute('aria-selected', false);

        this.tagsPerforming();
        this._updateCount();
      }
    })
  }

  clearAll() {
    this.options.forEach(option => option.setAttribute('aria-selected', false));
    this.addingLabels();
    this._updateCount();
  }

  _clearAllInit() {
    document.addEventListener('click', (evt) => {
      if (evt.target.classList.contains('js-applyed-tag-reset') || evt.target.closest('.js-applyed-tag-reset')) {
        let target = evt.target.classList.contains('js-applyed-tag-reset') ? evt.target : evt.target.closest('.js-applyed-tag-reset');

        this.clearAll()
        this.tagsPerforming();
      }
    });
  }
}
