const openModal = () => {

  // возвращает куки с указанным name,
  // или undefined, если ничего не найдено
  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  const mailingBlock = document.querySelector('.mailing');
  if (!mailingBlock) {
    return;
  }
  const mailForm = mailingBlock.querySelector('form');
  const mailPopup = document.querySelector('.modal--subscription');
  const closePopupMail = document.querySelector('.modal__close-btn--register');
  const buttonPopupMail = document.querySelector('.button--register');
  const error = document.querySelector('.mailing__form-error');
  const email = document.querySelector('#email');

  email.oninvalid = invalid;

  function invalid() {
    error.removeAttribute('hidden');
  }

  email.addEventListener('invalid', function (evt) {
    evt.preventDefault();
  });

  const closeModal = () => {
    mailPopup.classList.remove('modal--active');
  };

  const initModal = function (form, modal, closeButton, button) {
    if (mailForm) {
      form.addEventListener('submit', function () {
        error.setAttribute('hidden', '');

        let xhr = new XMLHttpRequest();
        xhr.open('POST', form.getAttribute('action'), true);
        let formData = new FormData(form);
        xhr.setRequestHeader('csrfmiddlewaretoken', getCookie('csrftoken'));
        xhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        try {
          xhr.send(formData);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }

        xhr.onload = function () {
          modal.classList.add('modal--active');
        };


      });
      closeButton.addEventListener('click', function () {
        closeModal();
      });
      button.addEventListener('click', function () {
        closeModal();
      });
      const onEscPress = (evt) => {
        const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';
        if (isEscKey) {
          evt.preventDefault();
          closeModal();
        }
      };
      document.addEventListener('keydown', onEscPress);
      const overlay = mailPopup.querySelector('.modal__overlay');
      overlay.addEventListener('click', () => {
        closeModal();
      });
    }
  };

  initModal(mailForm, mailPopup, closePopupMail, buttonPopupMail);
};

export {openModal};
