const links = [...document.querySelectorAll('[data-side-menu="link"]')];

const openMenuItem = (link) => {
  const context = link.parentNode;
  const items = [...context.parentNode.children];

  items.forEach((item) => {
    if (item !== context) {
      item.classList.add('is-hidden');
    }
  });
};

const closeMenuItem = (link) => {
  link.classList.remove('is-active');

  // Закрывать каждый подпункт отдельно
  const context = link.parentNode;
  const items = [...context.parentNode.children];

  items.forEach((item) => {
    item.classList.remove('is-hidden');
  });
  window.history.pushState(null, null, link.dataset.parentUrl + window.location.search);
};

const toggleSideMenu = () => {
  const sideMenu = document.querySelectorAll('.sidebar-nav__list');

  if (!sideMenu) {
    return;
  }

  sideMenu.forEach((sideMenuItem) => {

    sideMenuItem.classList.add('is-js');

    if (!links) {
      return;
    }

    let activeLinks = [];

    sideMenuItem.querySelectorAll('.sidebar-nav__link.is-active').forEach(function (item) {
      activeLinks.push(item);
    });

    for (let link = 0; link < links.length; link++) {
      links[link].addEventListener('click', () => {

        if (!links[link].classList.contains('is-active')) {
          setTimeout(() => {
            openMenuItem(links[link]);
            const sibling = links[link].nextElementSibling;

            if (sibling) {
              links[link].classList.add('is-active');
              sibling.querySelectorAll(':scope > .sidebar-nav__item').forEach(function (item) {
                item.classList.remove('is-hidden');
              });
            }

            activeLinks.push(links[link]);


            if (activeLinks.length > 1) {
              activeLinks[activeLinks.length - 2].classList.add('is-hidden');
            }
          }, 100);


        } else {

          setTimeout(() => {
            closeMenuItem(links[link]);

            if (activeLinks.length > 1) {
              activeLinks[activeLinks.length - 2].classList.remove('is-hidden');
            }

            activeLinks.splice(activeLinks.length - 1, 1);
          }, 100);
        }
      });
    }

  });

};

export {toggleSideMenu};
