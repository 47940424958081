const nawsHeader = () => {
  const body = document.querySelector('body');
  const news = document.querySelector('.header__news');
  const buttonClose = document.querySelector('.header__news-close');
  const toggle = document.querySelector('.header__news-toggle');

  if (!news) {
    // body.classList.add('news-close');
    return;
  }

  buttonClose.addEventListener('click', function () {
    body.classList.add('news-close');
  });

  toggle.addEventListener('click', function () {
    news.classList.toggle('header__news--open');
  });

};
export {nawsHeader};
