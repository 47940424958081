const initActiveCheckbox = () => {
  const checkoxWrapper = document.querySelectorAll('[data-checkbox-wrapper]');

  if (!checkoxWrapper) {
    return;
  }

  checkoxWrapper.forEach((item) => {
    const checkox = item.querySelector('[data-input-checkbox]');

    checkox.addEventListener('click', function () {
      if (checkox.checked === true) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
  });
};

const toggleButton = () => {
  const buttonWrap = document.querySelectorAll('.block-price__add');

  if (!buttonWrap) {
    return;
  }

  buttonWrap.forEach((item) => {
    item.addEventListener('click', function () {
      const button = item.querySelector('.card-button');
      button.classList.toggle('card-add');
    });
  });

};

export {initActiveCheckbox, toggleButton};
