const SERVISES = {
  doc1: [
    {
      id: 'id-1',
      value: '21374',
      text: 'Первичный приём',
      cost: '700 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '21374',
      text: 'Консультация врача-гинеколога повторная',
      cost: '700 ₽',
    },
    {
      id: 'id-3',
      value: '21374',
      text: 'Взятие мазка (микрофлору, цитологию, ПЦР-диагностику, на кольпоцитологию, на онкоцитологию) – каждый',
      cost: '700 ₽',
    },
    {
      id: 'id-4',
      value: '21374',
      text: 'Индивидуальный подбор средств контрацепции',
      cost: '700 ₽',
    }
  ],
  doc2: [
    {
      id: 'id-1',
      value: '1301',
      text: 'Сделать укол',
      cost: '500 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '1306',
      text: 'Подуть на место укола',
      cost: '1500 ₽',
    },
    {
      id: 'id-3',
      value: '1359',
      text: 'Сказать "Прям как ребенок маленький" и осуждающе мотнуть головой',
      cost: '8000 ₽',
    }
  ],
  doc3: [
    {
      id: 'id-1',
      value: '1301',
      text: 'Сделать укол',
      cost: '500 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '1306',
      text: 'Подуть на место укола',
      cost: '1500 ₽',
    },
    {
      id: 'id-3',
      value: '1359',
      text: 'Сказать "Прям как ребенок маленький" и осуждающе мотнуть головой',
      cost: '8000 ₽',
    }
  ],
  doc4: [
    {
      id: 'id-1',
      value: '1301',
      text: 'Подуть на место укола',
      cost: '500 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '1306',
      text: 'Сделать укол',
      cost: '1500 ₽',
    },
    {
      id: 'id-3',
      value: '1359',
      text: 'Сказать "Прям как ребенок маленький" и осуждающе мотнуть головой',
      cost: '8000 ₽',
    }
  ],
  doc5: [
    {
      id: 'id-1',
      value: '1301',
      text: 'Сделать укол',
      cost: '500 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '1306',
      text: 'Подуть на место укола',
      cost: '1500 ₽',
    },
    {
      id: 'id-3',
      value: '1359',
      text: 'Сказать "Прям как ребенок маленький" и осуждающе мотнуть головой',
      cost: '8000 ₽',
    }
  ],
  doc6: [
    {
      id: 'id-1',
      value: '1301',
      text: 'Сделать укол',
      cost: '500 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '1306',
      text: 'Подуть на место укола',
      cost: '1500 ₽',
    },
    {
      id: 'id-3',
      value: '1359',
      text: 'Сказать "Прям как ребенок маленький" и осуждающе мотнуть головой',
      cost: '8000 ₽',
    }
  ],
  doc7: [
    {
      id: 'id-1',
      value: '1301',
      text: 'Сделать укол',
      cost: '500 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '1306',
      text: 'Подуть на место укола',
      cost: '1500 ₽',
    },
    {
      id: 'id-3',
      value: '1359',
      text: 'Сказать "Прям как ребенок маленький" и осуждающе мотнуть головой',
      cost: '8000 ₽',
    }
  ],
  doc8: [
    {
      id: 'id-1',
      value: '1301',
      text: 'Сделать укол',
      cost: '500 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '1306',
      text: 'Подуть на место укола',
      cost: '1500 ₽',
    },
    {
      id: 'id-3',
      value: '1359',
      text: 'Сказать "Прям как ребенок маленький" и осуждающе мотнуть головой',
      cost: '8000 ₽',
    }
  ],
  doc9: [
    {
      id: 'id-1',
      value: '1301',
      text: 'Сделать укол',
      cost: '500 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '1306',
      text: 'Подуть на место укола',
      cost: '1500 ₽',
    },
    {
      id: 'id-3',
      value: '1359',
      text: 'Сказать "Прям как ребенок маленький" и осуждающе мотнуть головой',
      cost: '8000 ₽',
    }
  ],
  doc10: [
    {
      id: 'id-1',
      value: '1301',
      text: 'Сделать укол',
      cost: '500 ₽',
      isActive: true,
    },
    {
      id: 'id-2',
      value: '1306',
      text: 'Подуть на место укола',
      cost: '1500 ₽',
    },
    {
      id: 'id-3',
      value: '1359',
      text: 'Сказать "Прям как ребенок маленький" и осуждающе мотнуть головой',
      cost: '8000 ₽',
    }
  ],
};

export {SERVISES};
