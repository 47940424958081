import {setupModal} from './modal';
import {stopSlider, startSlider} from '../slider-stories';
import {openSearchModalCallback} from '../form-focus';

// настраиваем модалки тут, все колбеки импортим, а не создаем из этого модуля простыню
const initModal = (modalId, modal, btn) => {
  modalId = modalId.indexOf('slider') >= 0 ? modalId = 'slider' : modalId;

  switch (modalId) {
    case 'search':
      setupModal(modal, false, btn, () => openSearchModalCallback(modal), false, false);
      break;
    case 'slider':
      setupModal(modal, () => stopSlider(modal), btn, () => startSlider(modal), false, false);
      break;
    default:
      setupModal(modal, false, btn, false, false, false);
      break;
  }
};

// аргументы setupModal(modal, closeCallback, modalBtns, openCallback, noPrevDefault, preventScrollLock)
// возможна инициализация только с первыми аргументом,
// если вам нужно открывать модалку в другом месте под какими-нибудь условиями
const initModals = () => {
  const modals = document.querySelectorAll('.modal:not(.is-initialized)');
  const modalBtns = document.querySelectorAll('[data-modal]');

  // фикс для редких случаев, когда модалка появляется при загрузке страницы
  if (modals.length) {
    modals.forEach((el) => {
      setTimeout(() => {
        el.classList.remove('modal--preload');
        el.classList.add('is-initialized');
      }, 100);
    });
  }

  if (modalBtns.length) {
    modalBtns.forEach((btn) => {
      const modalId = btn.dataset.modal;
      const modal = document.querySelector(`.modal--${modalId}`);
      if (modal) {
        initModal(modalId, modal, btn);
      }
    });
  }
};

window.initModals = initModals;

export {initModals};
