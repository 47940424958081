const showRubric = () => {
  const rubricBlock = document.querySelector('[data-rubric-block]');
  if (!rubricBlock) {
    return;
  }
  const items = rubricBlock.querySelectorAll('.card-description__rubric-wrap');

  if (items.length <= 1) {
    rubricBlock.classList.add('single');
  }
};

export {showRubric};
