
export const findButtonEnabling = () => {

  const url = new URL(window.location);
  const searchParams = url.searchParams;

  const PARAMS = ['search', 'tags', 'gender', 'organs'];

  const urlHasParams = PARAMS.filter(param => {
    return searchParams.has(param);
  });


  const btn = document.querySelector('.js-search-iterable-text');

  if (!btn) {
    return;
  }

  if (urlHasParams.length > 0) {
    btn.classList.add('visible');
  } else {
    btn.classList.remove('visible');
  }

};
