const showAllCards = () => {
  const cardsBlok = document.querySelectorAll('[data-cards-block]');
  if (!cardsBlok) {
    return;
  }

  cardsBlok.forEach((item) => {
    const button = item.querySelector('[data-button-all]');
    button.addEventListener('click', function () {
      button.style.display = 'none';
      const cards = item.querySelectorAll('[data-card-analysis]');
      cards.forEach((card) => {
        card.style.display = 'flex';
      });
    });
  });
};

export {showAllCards};
