import {disableBodyScrollIos, enableBodyScrollIos} from './toggle-body-scroll';
import {addPaddingHeader, removePaddingHeader} from './toggle-padding-header';


const modalAddres = () => {
  const modal = document.querySelector('[data-modal-address]');
  if (!modal) {
    return;
  }

  const buttonOpen = document.querySelector('[data-open-modal-address]');
  const buttonClose = document.querySelector('[data-modal-address-close]');
  const overlay = document.querySelector('[data-modal-address-overlay]');

  const openModal = () => {
    modal.classList.add('is-open');
    disableBodyScrollIos();
    addPaddingHeader();
    // disableBodyScroll(modal, {
    //   reserveScrollBarGap: true,
    // });
  };

  const closewModal = () => {
    modal.classList.remove('is-open');
    setTimeout(() => {
      enableBodyScrollIos();
      removePaddingHeader();
      // enableBodyScroll(modal);
    }, 300);
  };

  buttonOpen.addEventListener('click', function () {
    openModal();
  });

  buttonClose.addEventListener('click', function () {
    closewModal();
  });


  overlay.addEventListener('click', function () {
    closewModal();
  });
};

export {modalAddres};

// import {
//   disableBodyScroll,
//   enableBodyScroll
// } from 'body-scroll-lock';
