const inputMask = () => {
  const inputsNamb = document.querySelectorAll('[data-input-number]');
  if (!inputsNamb) {
    return;
  }

  inputsNamb.forEach((input) => {
    // eslint-disable-next-line
    let numberMask = IMask(
        input,
        {
          mask: Number,
          min: 0,
          max: 9,
          thousandsSeparator: ' ',
        });
  });
};

const intlInputPhone = () => {
  const inputPhone = document.querySelector('[data-input-phone]');
  if (!inputPhone) {
    return;
  }
  const inputPhoneWrap = document.querySelector('.customer-data__phone');

  // eslint-disable-next-line
  let phoneMask = IMask(
      inputPhone, {
        mask: '(000)000-00-00',
      });


  window.intlTelInput(inputPhone, {
    initialCountry: 'ru',
    separateDialCode: true,
    preferredCountries: ['ru'],
    dropdownContainer: inputPhoneWrap,
    utilsScript: 'js/utils.js',
  });

};

export {inputMask, intlInputPhone};
