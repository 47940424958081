const toggleTab = () => {
  const tabs = document.querySelectorAll('.analyzes__btn');
  const toggle = document.querySelector('.analyzes__toggle-pointer');
  if (!tabs) {
    return;
  }

  tabs.forEach((item) => {
    item.addEventListener('click', function () {
      if (item.classList.contains('analyzes__btn--right')) {
        toggle.classList.add('analyzes__toggle-pointer--right');
      } else {
        toggle.classList.remove('analyzes__toggle-pointer--right');
      }
    });
  });
};

export {toggleTab};
