/*
import {SPECIALISTS} from './../data/specialists';
import {SERVISES} from './../data/servises';
import {reRenderSelectOptions} from './init-specialist-services';

const renderCard = (data, index, template) => {
  const item = template.content.cloneNode(true);
  const wrap = item.querySelector('.specialist__cards-wrapper');
  const card = item.querySelector('.specialist-card');

  const inputRadio = item.querySelector('input[type="radio"]');
  const label = item.querySelector('label');
  const img = label.querySelector('img');
  const name = item.querySelector('.specialist-card__info h4');
  const position = item.querySelector('.specialist-card__info p');
  const modalLink = item.querySelector('.specialist-link a');

  wrap.setAttribute('data-filter', data.filter);
  card.setAttribute('data-id', data.id);

  inputRadio.setAttribute('id', `specialist${index}`);
  label.setAttribute('for', `specialist${index}`);

  img.setAttribute('src', `/static/build/img/content/${data.img}.jpg`);
  img.setAttribute('srcset', `/static/build/img/content/${data.img}@2x.jpg 2x`);
  img.setAttribute('width', '76');
  img.setAttribute('height', '76');
  img.setAttribute('alt', `Фото ${data.name}`);

  modalLink.setAttribute('data-modal', data.modal);

  name.textContent = data.name;
  position.textContent = data.position;

  return item;
};

const renderCards = (data, wrap, template) => {
  data.forEach((item, i) => {
    wrap.appendChild(renderCard(item, ++i, template));
  });
};


const initSpecialistFilter = () => {
  // const template = document.querySelector('#specialist-card');
  const wrap = document.querySelector('.specialists__cards');
  const activeBtn = document.querySelector('.specialists__buttons .is-active button');
  const select = document.querySelector('.price-select');

  // if (!template) {
  //   return;
  // }

  const filterCards = (filter) => {
    Array.from(wrap.children).forEach((card) => {
      card.classList.toggle('specialist__cards-wrapper--hidden', card.dataset.filter !== filter);
      card.classList.toggle('specialist__cards-wrapper--active', card.dataset.filter === filter);
    });

    const activeCards = wrap.querySelectorAll('.specialist__cards-wrapper--active');

    if (activeCards.length) {
      reRenderSelectOptions(select, SERVISES[activeCards[0].querySelector('.specialist-card').dataset.id]);
    }
  };

  // renderCards(SPECIALISTS, wrap, template);
  // reRenderSelectOptions(select, SERVISES[wrap.querySelector('.specialist-card').dataset.id]);
  filterCards(activeBtn.dataset.filter);

  document.addEventListener('click', (e) => {
    if (e.target.closest('.specialists__buttons button')) {
      const btn = e.target.closest('.specialists__buttons button');

      if (btn.closest('.is-active')) {
        return;
      }

      filterCards(btn.dataset.filter);
    }
  });
};


export {initSpecialistFilter};
*/

const initSpecialistFilter = function () {
  const filtersBtns = document.querySelectorAll('.buttons-list .buttons-list__button');

  filtersBtns.forEach(function (item) {
    item.addEventListener('click', function () {
      if (item.classList.contains('buttons-list__button--active')) {
        return;
      }
      filtersBtns.forEach(function (btn) {
        btn.classList.remove('buttons-list__button--active');
        item.classList.add('buttons-list__button--active');
      });

      let filterName = item.dataset.filter;

      let staffBlocks = document.querySelectorAll('.specialist__cards-wrapper');
      let staffBlocksToShow = document.querySelectorAll(`.specialist__cards-wrapper[data-filter="${filterName}"]`);

      staffBlocks.forEach(function (staffBlock) {
        staffBlock.classList.remove('specialist__cards-wrapper--active');
        staffBlock.classList.add('specialist__cards-wrapper--hidden');
      });

      staffBlocksToShow.forEach(function (staffBlockToShow) {
        staffBlockToShow.classList.remove('specialist__cards-wrapper--hidden');
        staffBlockToShow.classList.add('specialist__cards-wrapper--active');
      });

    });
  });

};

export {initSpecialistFilter};
