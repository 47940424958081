import { DynamicPagination } from "./DynamicPagination";

let dynamicPagination;

const initDynamicPagination = () => {

  const container = document.querySelector('#analyzes_rubrics_list');

  if (!container) return;

  dynamicPagination = new DynamicPagination(container);

  window.dynamicPagination = dynamicPagination;

  dynamicPagination.init();
};

export {initDynamicPagination, dynamicPagination};