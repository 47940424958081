const scrollAnchors = () => {
  const anchors = document.querySelectorAll('[data-anchor]');
  if (!anchors) {
    return;
  }

  anchors.forEach((link) => {
    link.addEventListener('click', function (evt) {
      evt.preventDefault();
      let href = this.getAttribute('href').substring(1);
      const scrollTarget = document.getElementById(href);

      const topOffset = 160;
      const elementPosition = scrollTarget.getBoundingClientRect().top;
      const offsetPosition = elementPosition - topOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth',
      });
    });
  });


  const anchorTop = document.querySelector('.anchor-top');
  const blockDescription = document.querySelector('.block-description');
  if (!anchorTop && !blockDescription) {
    return;
  }

  const footer = document.querySelector('.footer');
  const PADDING_TOP = 300;
  const PADDING_TOP_FOOTER = 500;

  const breakpoint = window.matchMedia('(max-width:767px)');
  const breakpointChecker = () => {
    if (breakpoint.matches) {
      window.addEventListener('scroll', function () {
        let topBlock = blockDescription.getBoundingClientRect().top;
        let topFooter = footer.getBoundingClientRect().top;
        if (topBlock < PADDING_TOP && topFooter > PADDING_TOP_FOOTER) {
          anchorTop.style.display = 'flex';
        } else {
          anchorTop.style.display = 'none';
        }
      });
    } else {
      anchorTop.style.display = 'none';
    }
  };
  breakpoint.addListener(breakpointChecker);
  breakpointChecker();
};
export {scrollAnchors};
