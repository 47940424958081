import {Russian} from '../vendor/ru';
import flatpickr from '../vendor/flatpickr.min';


const calendar = () => {
  const calendarElement = document.querySelector('.js-calendar');

  if (calendarElement) {
    flatpickr.localize(Russian);
    flatpickr(calendarElement, {
      disableMobile: 'true',
      monthSelectorType: 'static',
      minDate: 'today',
      wrap: true
    });
  }
};

export {calendar};
